import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./NewSimulation.css";
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default class NewNote extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            name: "Test",
            startDate: new Date("01/01/2018"),
            endDate: new Date(),
            duration: 1,
            stopLoss: 300,
            takeProfit: 25,
            buySignal: "TWS",
            sellSignal: "TBC",
            orderSize: 600000,
            buyDailyCost: -60.3,
            sellDailyCost: -7.54
        };
    }

    validateForm() {
        if (typeof this.state.name !== 'string' || isNaN(this.state.duration) || isNaN(this.state.stopLoss) ||
            isNaN(this.state.takeProfit) || isNaN(this.state.orderSize) || isNaN(this.state.buyDailyCost) ||
            isNaN(this.state.sellDailyCost) || this.state.name.length === 0 || this.state.duration.length === 0 ||
            this.state.stopLoss.length === 0 || this.state.takeProfit.length === 0 || this.state.orderSize.length === 0 ||
            this.state.buyDailyCost.length === 0 || this.state.sellDailyCost.length === 0 )
            return false;
        else
            return true;
    }

    setStartDate(date) {
        this.setState({ startDate: date});
    }

    setEndDate(date) {
        this.setState({ endDate: date});
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            await this.createSimulation({
                name: this.state.name,
                startDate: (this.state.startDate.getTime()/1000),
                endDate: (this.state.endDate.getTime()/1000),
                duration: this.state.duration,
                takeProfit: this.state.takeProfit,
                stopLoss: this.state.stopLoss,
                buySignal: this.state.buySignal,
                sellSignal: this.state.sellSignal,
                orderSize: this.state.orderSize,
                buyDailyCost: this.state.buyDailyCost,
                sellDailyCost: this.state.sellDailyCost,
                status: "NEW"
            });
            this.props.history.push("/simulations");
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }

    createSimulation(simulation) {
        return API.post("simulations", "/", {
            body: simulation
        });
    }

    render() {
        return <div className="NewSimulation">
            <form onSubmit={this.handleSubmit} horizontal="true">
                <FormGroup>
                    <Col sm={4}>
                        <ControlLabel>Simulation Name</ControlLabel>
                        <FormControl type="text" value={this.state.name} placeholder="Enter brief description for the simulation"
                            onChange={this.handleChange} id="name"/>
                    </Col>
                    <Col sm={4}>
                        <ControlLabel>Start Date</ControlLabel>
                        <div>
                            <DatePicker className="form-control" selected={this.state.startDate}
                                onChange={this.setStartDate.bind(this)} id="startDate"/>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <ControlLabel>End Date</ControlLabel>
                        <div>
                            <DatePicker className="form-control" selected={this.state.endDate}
                                onChange={this.setEndDate.bind(this)} id="endDate"/>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={4}>
                        <ControlLabel>Duration (Days)</ControlLabel>
                        <FormControl type="text" value={this.state.duration} placeholder="Enter duration in days"
                            onChange={this.handleChange} id="duration"/>
                    </Col>
                    <Col sm={4}>
                        <ControlLabel>Take Profit (Pips)</ControlLabel>
                        <FormControl type="text" value={this.state.takeProfit} placeholder="Enter take profit in pips"
                            onChange={this.handleChange} id="takeProfit"/>
                    </Col>
                    <Col sm={4}>
                        <ControlLabel>Stop Loss (Pips)</ControlLabel>
                        <FormControl type="text" value={this.state.stopLoss} placeholder="Enter stop loss in pips"
                            onChange={this.handleChange} id="stopLoss"/>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={4}>
                        <ControlLabel>Order Size (Units)</ControlLabel>
                        <FormControl type="text" value={this.state.orderSize} placeholder="Enter duration in days"
                            onChange={this.handleChange} id="orderSize"/>
                    </Col>
                    <Col sm={4}>
                        <ControlLabel>Finance Cost - Buy Order (Daily)</ControlLabel>
                        <FormControl type="text" value={this.state.buyDailyCost} placeholder="Enter take profit in pips"
                            onChange={this.handleChange} id="buyDailyCost"/>
                    </Col>
                    <Col sm={4}>
                        <ControlLabel>Finance Cost - Sell Order (Daily)</ControlLabel>
                        <FormControl type="text" value={this.state.sellDailyCost} placeholder="Enter stop loss in pips"
                            onChange={this.handleChange} id="sellDailyCost"/>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={6}>
                        <ControlLabel>Buy Signal</ControlLabel>
                        <FormControl componentClass="select" value={this.state.buySignal} onChange={this.handleChange}
                                     id="buySignal">
                            <option value="TWS">Three White Soldiers</option>
                            <option value="ABBU">Abandoned Baby Bullish</option>
                            <option value="HPGB">High Price Gapping Bullish</option>
                        </FormControl>
                    </Col>
                    <Col sm={6}>
                    <ControlLabel>Sell Signal</ControlLabel>
                        <FormControl componentClass="select" value={this.state.sellSignal} onChange={this.handleChange}
                                     id="sellSignal">
                            <option value="TBC">Three Black Crows</option>
                            <option value="ABBE">Abandoned Baby Bearish</option>
                            <option value="LPGB">Low Price Gapping Bearish</option>
                        </FormControl>
                    </Col>
                </FormGroup>
                <LoaderButton
                    block
                    bsStyle="primary"
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Create"
                    loadingText="Creating…"
                />
            </form>
        </div>;
    }
}