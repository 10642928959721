import React, { Component } from "react";
import CanvasJSReact from '../assets/canvasjs.react';
//import Pagination from "react-bootstrap/Pagination";
import {Grid,  Row, Col, Pager, ButtonToolbar, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import { API } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.css";
let CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            startDate: new Date("01/01/2020"),
            dataPoints: [],
            previousPage: null,
            currentPage: null,
            nextPage: null,
            orderBy: 1
        };
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Candles</h1>
                <p>A Candlestick viewer</p>
            </div>
        );
    }

    isFirstPage(){
        return (this.state.previousPage? false : true);
    }

    getCandleColor(candle){
        if (candle.complexPattern  && candle.complexPattern === "TWS")
            return "orange";
        else if (candle.complexPattern  && candle.complexPattern === "TBC")
            return "red";
        else if (candle.pattern === "LWC")
            return "black";
        else if (candle.pattern === "LBC")
            return "green"
        else
            return null;
    }

    isLastPage(){
        return (this.state.nextPage? false : true);
    }

    handleOnClickNext = event => {
        event.preventDefault();
        this.componentDidMount(false);
    };

    handleRadioChange(e) {
        this.setState({ orderBy: e, previousPage: null, currentPage: null, nextPage: null } , () => {
            this.componentDidMount(false);
        });
    }

    handleChange(date) {
        this.setState({ startDate: date, orderBy: 1, previousPage: null, currentPage: null, nextPage: null } , () => {
            this.componentDidMount(false);
        });
    }

    handleOnClickPrevious = event => {
        event.preventDefault();
        this.componentDidMount(true);
    };

    renderNotes(dataPoints) {
        const options = {
            theme: "light2", // "light1", "light2", "dark1", "dark2"
            animationEnabled: true,
            zoomEnabled: true,
            axisX: {
                valueFormatString: "DD-MM-YY hhtt",
                gridThickness: 1,
                interval:10
            },
            axisY: {
                includeZero:false,
                title: "Price"
            },
            data: [{
                type: "candlestick",
                showInLegend: true,
                name: "AUD/USD 10 Minutes",
                yValueFormatString: "###0.00000",
                xValueFormatString: "DD MMM YY HH:MM",
                xValueType: "dateTime",
                dataPoints: dataPoints
            }
            ]
        }

        return (
            <Grid>
                <Row className="show-grid">
                    <Col md={6} xs={6} className="text-center">
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange.bind(this)}
                        />
                    </Col>
                    <Col md={6} xs={6}>
                        <ButtonToolbar>
                            <ToggleButtonGroup type="radio" name="options"
                                               value={this.state.orderBy}
                                               onChange={this.handleRadioChange.bind(this)} justified>
                                <ToggleButton value={1}>Date</ToggleButton>
                                <ToggleButton value={2}>High</ToggleButton>
                                <ToggleButton value={3}>Low</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <Row>
                            <Pager>
                                <Pager.Item previous onClick={this.handleOnClickPrevious}
                                            disabled={this.isFirstPage()}>
                                    &larr; Previous Page
                                </Pager.Item>
                                <Pager.Item next onClick={this.handleOnClickNext}
                                            disabled={this.isLastPage()}>
                                    Next Page &rarr;
                                </Pager.Item>
                            </Pager>
                </Row>
                <Row className="show-grid">
                    <Col className="text-center">
                        <div>
                            <CanvasJSChart options = {options} onRef={ref => this.chart = ref}
                            />
                            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderNotes(this.state.dataPoints) : this.renderLander()}
            </div>
        );
    }

    async componentDidMount(isPrevious) {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            console.log("Previous: " + this.state.previousPage);
            console.log("Current: " + this.state.currentPage);
            console.log("Next: " + this.state.nextPage);
            const candles = await this.candles(this.state.startDate, (isPrevious ? this.state.previousPage : this.state.nextPage), this.state.orderBy);
            console.debug(candles);
            let dataPoints = [];
            let chart = this.chart;
            let i = 1;
            for (const candle of candles.Items){
                let dataPoint = {
                    x: i,
                    y: [parseFloat(candle.mid.o),
                        parseFloat(candle.mid.h),
                        parseFloat(candle.mid.l),
                        parseFloat(candle.mid.c)],
                    label: (new Date(0)).setUTCMilliseconds(candle.time),
                    color: this.getCandleColor(candle)
                };
                dataPoints.push(dataPoint);
                i++;
            }
            this.setState({ dataPoints });
            console.debug(dataPoints);
            if (isPrevious){
                this.setState({ currentPage: this.state.previousPage });
                this.setState({ previousPage: null });
            }
            else{
                this.setState({ previousPage: this.state.currentPage });
                this.setState({ currentPage: this.state.nextPage });
            }
            this.setState({ nextPage: (candles.LastEvaluatedKey ? candles.LastEvaluatedKey : null) });
            chart.render();
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });
    }

    candles(from, lastKey, orderBy) {
        let queryString = "/AUD_USD/M10?from=" + from.getTime()/1000;
        if (lastKey && orderBy === 1)
            queryString += "&lastPage=" + lastKey.type + "," + lastKey.time;
        if (lastKey && orderBy === 2)
            queryString += "&lastPage=" + lastKey.type + "," + lastKey.high + "," + lastKey.time;
        if (lastKey && orderBy === 3)
            queryString += "&lastPage=" + lastKey.type + "," + lastKey.low + "," + lastKey.time;
        if (orderBy === 1)
            queryString += "&orderBy=Time";
        if (orderBy === 2)
            queryString += "&orderBy=High";
        if (orderBy === 3)
            queryString += "&orderBy=Low";

        console.debug("Query string: " + queryString);
        return API.get("candles", queryString);
    }

}
