const dev = {
    apiGatewayCandles: {
        REGION: "ap-southeast-2",
        URL: "https://test-api.plusfx.net/candles"
    },
    apiGatewaySimulations: {
        REGION: "ap-southeast-2",
        URL: "http://localhost:3000/"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_w7hNKbt1F",
        APP_CLIENT_ID: "57c8t01oueudg3g7mv0ghsf6f5",
        IDENTITY_POOL_ID: "ap-southeast-2:e32582e7-bd68-4a92-9440-5d96777eedd3"
    }
};

const test = {
    apiGatewayCandles: {
        REGION: "ap-southeast-2",
        URL: "https://test-api.plusfx.net/candles"
    },
    apiGatewaySimulations: {
        REGION: "ap-southeast-2",
        URL: "https://test-api.plusfx.net/simulations"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_w7hNKbt1F",
        APP_CLIENT_ID: "57c8t01oueudg3g7mv0ghsf6f5",
        IDENTITY_POOL_ID: "ap-southeast-2:e32582e7-bd68-4a92-9440-5d96777eedd3"
    }
};

const prod = {
    apiGatewayCandles: {
        REGION: "ap-southeast-2",
        URL: "https://api.plusfx.net/candles"
    },
    apiGatewaySimulations: {
        REGION: "ap-southeast-2",
        URL: "https://api.plusfx.net/simulations"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_w7hNKbt1F",
        APP_CLIENT_ID: "57c8t01oueudg3g7mv0ghsf6f5",
        IDENTITY_POOL_ID: "ap-southeast-2:e32582e7-bd68-4a92-9440-5d96777eedd3"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : (process.env.REACT_APP_STAGE === 'test' ? test : dev);

export default config;
