import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import "./Simulations.css";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            simulations: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const simulations = await this.simulations();
            this.setState({ simulations });
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });
    }

    simulations() {
        return API.get("simulations", "/");
    }

    renderSimulationsList(simulations) {
        return [{}].concat(simulations).map(
            (simulation, i) => {
                if (i !== 0) {
                    let summary = "";
                    if (simulation.simulation_result.successRate) {
                        var formatter = new Intl.NumberFormat('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                        });
                        summary = "Created: " + new Date(simulation.createdAt).toLocaleString() + ". Status: " + simulation.status +
                            ". Success Rate: " + simulation.simulation_result.successRate +
                            ". Profit: " + formatter.format(simulation.simulation_result.overallProfit);
                    }
                    else
                        summary = "Created: " + new Date(simulation.createdAt).toLocaleString() + ". Status: " + simulation.status
                    return <LinkContainer
                            key={simulation.id}
                            to={`/simulations/${simulation.id}`}
                        >
                            <ListGroupItem header={simulation.name}>
                                {summary}
                            </ListGroupItem>
                        </LinkContainer>
                }
                else {
                    return <LinkContainer
                        key="new"
                        to="/simulations/new"
                    >
                        <ListGroupItem>
                            <h4>
                                <b>{"\uFF0B"}</b> Create a new simulation
                            </h4>
                        </ListGroupItem>
                    </LinkContainer>
                }
            }
        );
    }


    renderLander() {
        return (
            <div className="lander">
                <h1>Simulations</h1>
                <p>The place to submit trading simulations based on candlesticks</p>
            </div>
        );
    }

    renderSimulations() {
        return (
            <div className="simulations">
                <PageHeader>Submitted Simulations</PageHeader>
                <ListGroup>
                    {!this.state.isLoading && this.renderSimulationsList(this.state.simulations)}
                </ListGroup>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderSimulations() : this.renderLander()}
            </div>
        );
    }
}