import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Simulations from "./containers/Simulations";
import Simulation from "./containers/Simulation";
import Login from "./containers/Login";
import NewSimulation from "./containers/NewSimulation";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps} />
        <AppliedRoute path="/candlesticks" exact component={Home} props={childProps} />
        <AppliedRoute path="/simulations" exact component={Simulations} props={childProps} />
        <AppliedRoute path="/login" exact component={Login} props={childProps} />
        <AppliedRoute path="/simulations/new" exact component={NewSimulation} props={childProps} />
        <AppliedRoute path="/simulations/:id" exact component={Simulation} props={childProps} />
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch>;

