import React, { Component } from "react";
import { API } from "aws-amplify";
import { Grid, Row, Col, Table, Panel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Simulation.css";


export default class Simulation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            simulation: null,
            startDate: new Date("01/01/2018"),
            endDate: new Date()
        };
    }

    async componentDidMount() {
        try {
            const simulation = await this.getSimulation();
            const startDate = new Date(0);
            startDate.setUTCSeconds(simulation.startDate);
            const endDate = new Date(0);
            endDate.setUTCSeconds(simulation.endDate);
            console.debug("StartDate: ", startDate);
            console.debug("EndDate: ", endDate);
            console.debug("simulation", simulation);

            this.setState({
                simulation,
                startDate,
                endDate
            });
        } catch (e) {
            alert(e);
        }
    }

    getSimulation() {
        return API.get("simulations", `${this.props.match.params.id}`);
    }

    deleteSimulation() {
        return API.del("simulations", `/${this.props.match.params.id}`);
    }

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this note?"
        );

        if (!confirmed) {
            return;
        }

        this.setState({ isDeleting: true });

        try {
            await this.deleteSimulation();
            this.props.history.push("/simulations");
        } catch (e) {
            alert(e);
            this.setState({ isDeleting: false });
        }
    }


    render() {
        var formatter = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        return (
            <div className="Simulations">
                {this.state.simulation &&
                <form onSubmit={this.handleSubmit}>
                    <Grid>
                        <Row>
                            <Col md={6}>
                                <Panel bsStyle="info">
                                    <Panel.Heading>
                                        <Panel.Title>Simulation Settings</Panel.Title>
                                    </Panel.Heading>
                                    <Table striped condensed>
                                        <tbody>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{this.state.simulation.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Starting Date:</td>
                                            <td>{this.state.startDate.toDateString()}</td>
                                        </tr>
                                        <tr>
                                            <td>End Date:</td>
                                            <td>{this.state.endDate.toDateString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Duration:</td>
                                            <td>{this.state.simulation.duration}</td>
                                        </tr>
                                        <tr>
                                            <td>Take Profit:</td>
                                            <td>{this.state.simulation.takeProfit}</td>
                                        </tr>
                                        <tr>
                                            <td>Stop Loss:</td>
                                            <td>{this.state.simulation.stopLoss}</td>
                                        </tr>
                                        <tr>
                                            <td>Buy Signal:</td>
                                            <td>{this.state.simulation.buySignal}</td>
                                        </tr>
                                        <tr>
                                            <td>Sell Signal:</td>
                                            <td>{this.state.simulation.sellSignal}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Size:</td>
                                            <td>{this.state.simulation.orderSize}</td>
                                        </tr>
                                        <tr>
                                            <td>Buy Finance Cost:</td>
                                            <td>{formatter.format(this.state.simulation.buyDailyCost)}</td>
                                        </tr>
                                        <tr>
                                            <td>Sell Finance Cost:</td>
                                            <td>{formatter.format(this.state.simulation.sellDailyCost)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Panel>
                            </Col>
                            <Col md={6}>
                                <Panel bsStyle="info">
                                    <Panel.Heading>
                                        <Panel.Title>Execution Results</Panel.Title>
                                    </Panel.Heading>
                                    <Table striped condensed>
                                        <tbody>
                                        <tr>
                                            <td>Status:</td>
                                            <td>{this.state.simulation.status}</td>
                                        </tr>
                                        <tr>
                                            <td>Success Rate:</td>
                                            <td>{this.state.simulation.status === "COMPLETED" ?
                                                this.state.simulation.simulation_result.successRate :
                                                "NA"}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Pips:</td>
                                            <td>{this.state.simulation.status === "COMPLETED" ?
                                                this.state.simulation.simulation_result.totalPips :
                                                "NA"}</td>
                                        </tr>
                                        <tr>
                                            <td>Overall Profit</td>
                                            <td>{this.state.simulation.status === "COMPLETED" ?
                                                formatter.format(this.state.simulation.simulation_result.overallProfit) :
                                                "NA"}</td>
                                        </tr>
                                        <tr>
                                            <td>Closed Trades:</td>
                                            <td>{this.state.simulation.status === "COMPLETED" ?
                                                this.state.simulation.simulation_result.closedTrades :
                                                "NA"}</td>
                                        </tr>
                                        <tr>
                                            <td>Finance Charges:</td>
                                            <td>{this.state.simulation.status === "COMPLETED" ?
                                                formatter.format(this.state.simulation.simulation_result.totalFinance) :
                                                "NA"}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Panel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <LoaderButton
                                    block
                                    bsStyle="danger"
                                    bsSize="large"
                                    isLoading={this.state.isDeleting}
                                    onClick={this.handleDelete}
                                    text="Delete"
                                    loadingText="Deleting…"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </form>}
            </div>
        );
    }
}